import aboutpng from '../assets/about.png'
const About = () => {
  return (
    <div className="flex flex-col mx-auto -mt-12 bg-white font-poppins sm:mt-4 md:mt-12" id='about'>
        <h1 className='font-poppins text-2xl text-center mb-4'>
                    About Us
                    <span className='w-28 h-2 block mx-auto bg-yellow-400 -mt-3'></span>
        </h1>
        <div className='md:flex md:mx-12 md:items-center md:justify-around md:space-x-6'>
        <img src={aboutpng} alt='about us' className='shadow-lg h-60 w-60 object-contain mx-auto rounded-full md:h-80 md:w-80 xl:h-100 xl:w-101' />
            <div className='mx-4 p-2 mt-4'>
                <h1 className='text-center text-2xl mb-4'>Get ready for fun!</h1>
                <p className='text-center flex flex-col md:text-lg'>We are a tours and travel company based in Kenya. We take you for trips across the
                    country at least once a month. Book with us and get to enjoy the ultimate fun. Make
                    friends and maybe meet your other half :)

                    <button className='mt-8 mx-auto bg-yellow-400 font-bold px-6 py-2 rounded-lg'>Get in Touch</button>

                </p>
            </div>
        </div>
    </div>
  )
}

export default About