import React from 'react'

const Footer = () => {
  return (
    <div className='text-center bg-gray-100 py-4 text'>
        &copy;2022 Designed and Developed by KayveTech
    </div>
  )
}

export default Footer