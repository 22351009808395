import About from './components/About';
import Contact from './components/Contact';
import Events from './components/Events';
import Footer from './components/Footer';
import Heropage from './components/Heropage';
import Navbar from './components/NavBar'

function App() {

  return (
    <div className='max-w-screen-2xl mx-auto'>
      <div className='sticky top-0 shadow-md bg-white z-50'>
        <Navbar />
      </div>
      <Heropage />
      <About />
      <Events />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
