import 'boxicons'
import { useState, useRef, useEffect } from 'react'

const NavBar = () => {
    const ref = useRef()

    const [clicked, setClicked] = useState(false)  

    useEffect(() => {
        const checkIfClickedOutside = e => {
            if(clicked && ref.current && !ref.current.contains(e.target)){
                setClicked(false)
            }
        }

        document.addEventListener("mousedown", checkIfClickedOutside)

        return () => {
        // Cleanup the event listener
        document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [clicked])
    
  return (
    <div className='mx-4 relative' ref={ref}>
        {/* desktop */}
        <div className='flex items-center justify-between py-4 px-2 text-xl'>
            {/* logo */}
            <p className="font-poppins cursor-pointer">G - Travels</p>

            {/* desktop links */}
            <div className='hidden lg:flex space-x-12 font-poppins'>
                <a href="#" className='border-b-2 border-white hover:border-yellow-400'>Home</a>
                <a href="#events" className='border-b-2 border-white hover:border-yellow-400'>Events</a>
                <a href="#about" className='border-b-2 border-white hover:border-yellow-400'>About</a>
                <a href="#contact" className='border-b-2 border-white hover:border-yellow-400'>Contact</a>
            </div>

            {/* menu icon */}
            <div className='lg:hidden'>
                <box-icon onClick={() => setClicked(!clicked)} name='menu'></box-icon>
            </div>
        </div>


        {/* mobile links */}
        <div className = {clicked === false ? 'hidden': 'block'}>
            <div className='lg:hidden bg-white rounded-lg z-50 flex flex-col items-center space-y-2 px-2 text-xl absolute w-full shadow p-4'>
                <a href="#" onClick={() => setClicked(!clicked)} className='w-full text-center hover:text-white'>Home</a>
                <a href="#events" onClick={() => setClicked(!clicked)} className='w-full text-center hover:text-white'>Events</a>
                <a href="#about" onClick={() => setClicked(!clicked)} className='w-full text-center hover:text-white'>About</a>
                <a href="#contact" onClick={() => setClicked(!clicked)} className='w-full text-center hover:text-white'>Contact</a>
            </div>
        </div>
    </div>
  )
}

export default NavBar