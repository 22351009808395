import React from 'react'

const Contact = () => {
  return (
    <div className='mt-16 mb-16 mx-4 space-y-8 md:max-w-5xl md:mx-auto'>
        <h1 className='font-poppins text-2xl text-center mb-4'>
                    Get in Touch
                    <span className='w-36 h-2 block mx-auto bg-yellow-400 -mt-3'></span>
        </h1>
        <div className='md:flex md:space-x-4'>
            <div className='md:w-1/2'>
            <label className="block text-gray-500 font-bold mb-1 md:mb-0 pr-4">Full Name</label>
            <input type='text' className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' />
            </div>

            <div className='md:w-1/2'>
            <label className="block text-gray-500 font-bold mb-1 md:mb-0 pr-4">Email</label>
            <input type='text' className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' />
            </div>

        </div>
        
        <div>
            <label className="block text-gray-500 font-bold mb-1 md:mb-0 pr-4">Subject</label>
            <input type='text' className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' />
            </div>

            <div>
            <label className="block text-gray-500 font-bold mb-1 md:mb-0 pr-4">Message</label>
            <textarea className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'></textarea>
            </div> 

            <div className='flex'>
            <button className='mx-auto bg-blue-400 text-white font-bold px-6 py-2 rounded'>Send Message</button>
            </div>

    </div>
  )
}

export default Contact