import './animate.css'

import poster1 from '../assets/poster1.jpg'
import poster2 from '../assets/poster2.jpg'
import poster3 from '../assets/poster3.jpg'
import poster4 from '../assets/poster4.jpg'

const Events = () => {


  return (
        <div className='mx-4 mt-16 font-poppins pt-4 sm:mt-4 md:mt-12' id='events'>
            <div className='relative mb-12'>
                <h1 className='font-poppins text-2xl text-center mb-4'>
                    Our Events
                    <span className='w-32 h-2 block mx-auto bg-yellow-400 -mt-3'></span>
                </h1>
            </div>

            <div className='grid grid-flow-row grid-cols-1 gap-4 sm:grid-cols-2 md:container md:mx-auto md:gap-8 lg:gap-12 lg:grid-cols-4'>
                <div className='h-100 w-64 shadow-lg mx-auto cursor-pointer bg-white'>
                    <img src={poster1} alt='poster' className='h-96 w-64 object-fill' />
                    <div className='flex flex-col p-2 space-y-2 bg-white'>
                        <div className='text-center'>
                            <span>Camp Ndunda Falls</span>
                        </div>
                        <div className='flex items-center justify-between'>
                            <span>Kshs. 3200</span>
                            <span>01/05/2022</span>
                        </div>
                    </div>
                </div>
                <div className='h-100 w-64 shadow-lg mx-auto cursor-pointer bg-white'>
                    <img src={poster2} alt='poster' className='h-96 w-64 object-fill' />
                    <div className='flex flex-col p-2 space-y-2 bg-white'>
                        <div className='text-center'>
                            <span>Kitengela Scary Bridge</span>
                        </div>
                        <div className='flex items-center justify-between'>
                            <span>Kshs. 2500</span>
                            <span>17/04/2022</span>
                        </div>
                    </div>
                </div>
                <div className='h-100 w-64 shadow-lg mx-auto cursor-pointer bg-white'>
                    <img src={poster3} alt='poster' className='h-96 w-64 object-fill' />
                    <div className='flex flex-col p-2 space-y-2 bg-white'>
                        <div className='text-center'>
                            <span>Mombasa Escapedes</span>
                        </div>
                        <div className='flex items-center justify-between'>
                            <span>Kshs. 5000</span>
                            <span>31/03/2022</span>
                        </div>
                    </div>
                </div>
                <div className='h-100 w-64 shadow-lg mx-auto cursor-pointer bg-white'>
                    <img src={poster4} alt='poster' className='h-96 w-64 object-fill' />
                    <div className='flex flex-col p-2 space-y-2 bg-white'>
                        <div className='text-center'>
                            <span>Ngong Hills Hike</span>
                        </div>
                        <div className='flex items-center justify-between'>
                            <span>Kshs. 1600</span>
                            <span>29/01/2022</span>
                        </div>
                    </div>
                </div>
            </div>
   
        </div>
  )
}

export default Events