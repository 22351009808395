import './animate.css'
import mainbg from '../assets/mainbg.webp'
import car from '../assets/car.webp'

const Heropage = () => {
  return (
    <div className='mt-8 md:mt-12' id='home'>
        <div className='space-y-2'>
            <p className='text-center font-poppins font-bold text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-5xl 2xl:text-6xl 2xl:tracking-wider'>
                Lifelong memories just a
            </p>
            <p className='text-center font-poppins font-bold text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-5xl 2xl:text-6xl 2xl:tracking-wider relative z-10'>
                    few seconds away
                    <span className='h-2 block w-56 -z-10 -mt-4 mx-auto bg-yellow-400 xl:w-100 2xl:w-108'></span>
            </p>
        </div>

        <p className='font-poppins text-md text-center mt-6 sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-2xl 2xl:mt-10'>Let's travel. See you there</p>

        <div className='flex mt-6 2xl:mt-10'>
            <button className='mx-auto bg-blue-900 px-6 py-2 text-white font-poppins rounded hover:bg-blue-800 transition-all font-bold lg:text-lg xl:text-lg 2xl:text-xl'>Explore Destinations</button>
        </div>

        <div className='relative overflow-hidden w-full'>
            <img src={mainbg} alt='mainbg' className='object-contain h-96 md:h-full' />
            <img src={car} alt='carmoving' className='absolute top-32 h-24 sm:top-36 sm:h-28 md:top-36 md:h-44 lg:top-40 lg:h-52 xl:h-60 2xl:h-72 animatecar' />
        </div>    
    </div>
  )
}

export default Heropage